<template>
  <div>
    <div class="charts-card charts-holders">
        <b-row class="wrapper-row">
          <b-col
          v-if="$ableTo('View Stores')"
          cols="12"
          md="4"
          class="customers-charts"
          >
          <b-card>
            <b-row
              class="charts-heading"
              no-gutters
            >
              <b-col
                cols="10"
                class="analytics-title widget-customers-label"
              >
                Stores
              </b-col>
              <b-col
                cols="2"
                class="text-right edit-button"
              >
                <a @click="showStoresModal = !showStoresModal">Edit</a>
              </b-col>
            </b-row>
            <div
              v-if="chartsLoading"
              class="chart-loader mb-1"
            >
              <b-spinner label="Loading..." />
            </div>
            <b-row
              v-else
              class="justify-content-center"
            >
              <b-col
                cols="12"
                xs="12"
                :md="$ableTo('View subscriptions') ? 4 : 6"
              >
                <card-customer-chart
                  :custom-total="true"
                  :height="200"
                  :chart-data="CanadaCustomerChartData"
                />
              </b-col>
              <b-col
                cols="12"
                xs="12"
                :md="$ableTo('View subscriptions') ? 4 : 6"
              >
                <card-customer-chart
                  :custom-total="true"
                  :height="200"
                  :chart-data="UsaCustomerChartData"
                />
              </b-col>
              <b-col
                v-if="$ableTo('View subscriptions')"
                cols="12"
                xs="12"
                :md="4"
              >
                <card-customer-chart
                  :custom-total="true"
                  :height="200"
                  :chart-data="SubscriptionCustomerChartData"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-row class="placeholder-wrapper-home d-none d-sm-block">
            <PlaceHolder />
          </b-row>
        </b-col>
          <b-col
            v-if="homeItemsLoading"
            cols="12"
            class="home-items-loader d-flex justify-content-center align-items-center"
            :md="$ableTo('View Stores') ? 4 : 6"
          >
            <b-card>
              <b-spinner label="Loading..." />
            </b-card>
          </b-col>

          <b-col
            v-else
            cols="12"
            class="home-items"
            :md="$ableTo('View Stores') ? 4 : 6"
          >
            <b-card>
              <home-customers-items :key="`HomeCustomersItemCount${HomeCustomersItemCount}`" :customers-items="customersItems" />
              <home-rides-items :key="`ridesItems${HomeCustomersItemCount}`" :rides-items="ridesItems" />
            </b-card>
          </b-col>

          <b-col
            cols="12"
            :md="$ableTo('View Stores') ? 4 : 6"
            class="product-orders"
          >
            <b-row
              class="charts-heading"
              no-gutters
            />
            <div
              v-if="loadAnalitycs"
              class="chart-loader"
            >
              <b-spinner label="Loading..." />
            </div>

            <b-row class="wrapper-row" v-else>
              <b-col
                :class="$ableTo('View Stores') ? '' : 'no-border'"
                cols="12"
              >
              <b-card>
                <card-statistics-card-chart-bar
                  :data="barsData"
                  :show-year-picker="false"
                  :data-loaded="dataLoaded"
                  @loadYearContent="loadData"
                  @showAnalyticsModal="showAnalyticsModalFn"
                />
              </b-card>
              </b-col>
            </b-row>
            <b-row class="wrapper-row mileage-wrapper">
              <b-col>
                <Mileage />
              </b-col>
            </b-row>
          </b-col>

        </b-row>


      <div class="stats-list-home">
        <stats-list  />
      </div>
    </div>
    <edit-analytics-targets
      :show-analytics-modal="showAnalyticsModal"
      @reloadAnalitycsTargetsCharts="load"
    />
    <edit-stores-targets
      :show-stores-modal="showStoresModal"
      :stores-form-data="storesFormData"
      @reloadSalesTargetsCharts="load"
    />
  </div>
</template>
<script>

import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import StatsList from '@/views/rides/StatsList.vue'
import CardStatisticsCardChartBar from '@/views/components/statistics/CardStatisticsCardChartBar.vue'
import CardCustomerChart from '@/views/components/statistics/CardCustomerChart.vue'
import moment from 'moment'
import EditAnalyticsTargets from '@/views/components/home/EditAnalyticsTargets.vue'
import EditStoresTargets from '@/views/components/home/EditStoresTargets.vue'
import Mileage from '@/views/components/home/Mileage.vue'
import PlaceHolder from '@/views/shared-components/general/Placeholder.vue'
import HomeCustomersItems from '@/views/components/statistics/HomeCustomersItems.vue'
import HomeRidesItems from '@/views/components/statistics/HomeRidesItems.vue'

export default {
  components: {
    StatsList,
    BCol,
    BRow,
    BCard,
    // CardProductOrdersChart,
    CardCustomerChart,
    CardStatisticsCardChartBar,
    EditAnalyticsTargets,
    EditStoresTargets,
    Mileage,
    PlaceHolder,
    HomeCustomersItems,
    HomeRidesItems,
  },
  data() {
    return {
      loadAnalitycs: false,
      dataLoaded: false,
      barsData: [],
      customersLoading: false,
      scootersLoading: false,
      chartsLoading: true,
      overallStatsLoading: true,
      showAnalyticsModal: false,
      showStoresModal: false,
      homeItemsLoading: false,
      HomeCustomersItemCount: 0,
      colors: {
        percentageColor: '#FFB930',
        exceedPercentageColor: '#24C23C',
      },
      ProductsChartData: {
        total_label: 'Customers',
        title: 'Customers',
        colors: ['#FFB930', '#FFB930'],
        series: [],
        labels: ['Shopify customers', 'App Users', 'Customers'],
        items_count: [],
        total_items_count: 0,
      },
      storesFormData: {
        ca: {
          id: 'ca',
          index: 1,
          icon: 'ca',
          title: 'Canada',
          data: {},
        },
        usa: {
          id: 'usa',
          index: 2,
          icon: 'usa',
          title: 'USA',
          data: {},
        },
        subs: {
          id: 'subs',
          index: 3,
          icon: 'hand',
          title: 'Subs',
          data: {},
        },
      },
      ScootersChartData: {
        total_label: 'Scooters',
        name: 'scooters',
        title: 'Scooters',
        colors: ['#FFB930', '#FFB930'],
        series: [],
        labels: ['Rides', 'Odometer', 'Scooters'],
        items_count: [],
        total_items_count: 0,
      },
      CanadaCustomerChartData: {
        total_label: 'Canada',
        icon: 'customer/ca.png',
        colors: ['#FFB930', '#FFB930'],
        series: [],
        labels: ['Canada'],
        store_link: 'https://apollo-mvmt.myshopify.com/admin',
        currentMonth: moment().format('MMMM-YYYY'),
        legends: [
          {
            text: 'Daily target',
            color: '#FFB930',
          },
          {
            text: 'Monthly target',
            color: '#FFB930',
          },
        ],
      },
      UsaCustomerChartData: {
        total_label: 'USA',
        icon: 'customer/usa.png',
        colors: ['#FFB930', '#FFB930'],
        series: [],
        labels: ['USA'],
        store_link: 'https://apollo-scooters-canada.myshopify.com/admin',
        currentMonth: moment().format('MMMM-YYYY'),
        legends: [
          {
            text: 'Daily target',
            color: '#FFB930',
          },
          {
            text: 'Monthly target',
            color: '#FFB930',
          },
        ],
      },
      SubscriptionCustomerChartData: {
        total_label: 'Subscriptions',
        icon: 'customer/hand.png',
        colors: ['#FFB930', '#FFB930'],
        series: [],
        labels: ['Subscriptions'],
        store_link: 'https://rideapollo.myshopify.com/admin',
        currentMonth: moment().format('MMMM-YYYY'),
        legends: [
          {
            text: 'Daily target',
            color: '#FFB930',
          },
          {
            text: 'Monthly target',
            color: '#FFB930',
          },
        ],
      },
      customersItems: {
        usa: {
          amount: 0,
        },
        ca: {
          amount: 0,
        },
        subs: {
          amount: 0,
        },
        android: {
          amount: 0,
        },
        ios: {
          amount: 0,
        },
      },
      ridesItems: {
        android: {
          amount: 0,
        },
        avg_trip: {
          amount: 0,
        },
        ios: {
          amount: 0,
        },
        mileage: {
          amount: 0,
        },
        trips_per_scooter: {
          amount: 0,
        },
      },
      numbersFormat: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      targetsLabels: ['Yesterday\'s target', 'From monthly target'],
      exceedPercentageColor: '#24C23C',
    }
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.load)

  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.load)
  },
  mounted() {
    this.$store.commit('menuConfig/SET_PAGE_TITLE', 'Home')
    this.load()
  },
  methods: {
    convertToInternationalCurrencySystem(labelValue) {
      const floatValue = Math.round(parseFloat(labelValue))

      return Math.abs(Number(floatValue)) >= 1.0e+9

        ? `${(Math.abs(Number(floatValue)) / 1.0e+9).toFixed(2)}B`
      // Six Zeroes for Millions
      // eslint-disable-next-line no-nested-ternary
        : Math.abs(Number(floatValue)) >= 1.0e+6

          ? `${(Math.abs(Number(floatValue)) / 1.0e+6).toFixed(2)}M`
        // Three Zeroes for Thousands
          : Math.abs(Number(floatValue)) >= 1.0e+3

            ? `${(Math.abs(Number(floatValue)) / 1.0e+3).toFixed(2)}K`

            : Math.abs(Number(floatValue))
    },
    setChartData(type, {
      currentMonthTarget, yearTarget, dailyTarget, monthDays, sales,
    }) {
      // set sales / sales targets percentage
      this[`${type}CustomerChartData`].monthSalesPercentage = 100 * (sales.mtd / (currentMonthTarget || 1))
      this[`${type}CustomerChartData`].dailySalesPercentage = 100 * (sales.yesterday / dailyTarget)

      // set sales numbers (got from shopify)
      this[`${type}CustomerChartData`].yesterdaySales = `$${this.convertToInternationalCurrencySystem(sales.yesterday)}`

      this[`${type}CustomerChartData`].monthSales = `$${this.convertToInternationalCurrencySystem(sales.mtd)}`

      this[`${type}CustomerChartData`].yearSales = `$${this.convertToInternationalCurrencySystem(sales.ytd)}`

      // set chart numbers
      this[`${type}CustomerChartData`].labels = [`$${this.convertToInternationalCurrencySystem(sales.yesterday)}`, `$${this.convertToInternationalCurrencySystem(sales.mtd)}`]
      this[`${type}CustomerChartData`].targets = [`$${this.convertToInternationalCurrencySystem(dailyTarget)}`, `$${this.convertToInternationalCurrencySystem(currentMonthTarget || 0)}`]
      this[`${type}CustomerChartData`].targetsLabels = this.targetsLabels

      // set targets
      this[`${type}CustomerChartData`].dailyTarget = `$${this.convertToInternationalCurrencySystem(dailyTarget)}`
      this[`${type}CustomerChartData`].monthTarget = `$${this.convertToInternationalCurrencySystem(currentMonthTarget || 0)}`
      this[`${type}CustomerChartData`].yearTarget = `$${this.convertToInternationalCurrencySystem(yearTarget || 0)}`

      // set colors depending on percentage.
      this[`${type}CustomerChartData`].yesterdayTargetClass = (currentMonthTarget / monthDays) < sales.yesterday ? 'text-success' : ''
      this[`${type}CustomerChartData`].monthTargetClass = (currentMonthTarget < sales.mtd) ? 'text-success' : ''
      this[`${type}CustomerChartData`].yearTargetClass = yearTarget < sales.ytd ? 'text-success' : ''

      this[`${type}CustomerChartData`].colors[0] = this[`${type}CustomerChartData`].dailySalesPercentage > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor
      this[`${type}CustomerChartData`].colors[1] = this[`${type}CustomerChartData`].monthSalesPercentage > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor

      // eslint-disable-next-line prefer-destructuring
      this[`${type}CustomerChartData`].legends[0].color = this[`${type}CustomerChartData`].colors[0]
    },

    load(showLoader = true,period = null, interval = null, dates = null, applyDates = false, type='both') {
      this.chartsLoading = true
      this.overallStatsLoading = true
      this.loadData(showLoader,period, interval, dates, applyDates = false,type)
      //this.load_sales()
      this.load_home_items(showLoader,period)
      this.loadScootersTypes()
    },
    loadScootersTypes() {
      this.$http.get(this.$resources.firmwares.scooterTypes).then(response => {
        const { data: { data } } = response
        this.scooterTypes = data.rows.map(element => ({ item: element.type, name: element.type }))
        this.scooterTypes.unshift({ item: 'All', name: 'All' })
      })
    },
    loadCustomers(currentyear = null) {
      this.loadData(true, currentyear, 'customers')
    },
    loadScooters(currentyear = null) {
      this.loadData(true, currentyear, 'scooters')
    },
    calculatePercentage(amount, target, value = false) {
      if (target === 0 || isNaN(target)) return '-'
      let calculate = ((parseInt(amount, 0) / parseInt(target, 0)) * 100)
      calculate = Math.round(calculate)
      if (value) return `${calculate}%`
      return `${calculate <= 100 ? calculate : 100}%`
    },
    cleanValue(value) {
      return isNaN(value) ? 0 : value
    },
    formattedValue(value) {
      return value ? parseInt(value, 0).toLocaleString(this.numbersFormat) : 0
    },
    getPeramsData(period) {
          let isYearSelector = false;
          let params = null;
          if(typeof period === 'object') {
            params  = period;
          }else {
            isYearSelector = true;
            const start_date = moment(`01/01/${period}`).startOf('year').format('YYYY-MM-DD')
            const end_date = moment(`01/01/${period}`).endOf('year').format('YYYY-MM-DD')
            params = period !== 'all-time' ? { start_date, end_date } : null
           }
          return { params, isYearSelector}
    },
    loadData(showLoader = true,period = null, interval = null, dates = null, applyDates = false, type="both") {
      if(showLoader) {
        this[`${type}Loading`] = true
        this.dataLoaded = false
      }


      let  analytics_period_data = this.getPeramsData(period)
      let  period_data = this.getPeramsData(period)


        if(analytics_period_data.isYearSelector) {
          analytics_period_data =  { year: period}
        } else {
          analytics_period_data = null
        }



      this.$http.get(this.$resources.targets.analytics.list, { params:  analytics_period_data }).then(r => {
        const { data: { data } } = r
        const yearTargets = data

        // load overall stats.
        this.$http.get(this.$resources.admins.stats, { params:  period_data.params }).then(response => {
          const overallStats = response.data.data
          this.ProductsChartData.series[0] = Math.round(100 * (overallStats.buyers / parseFloat(yearTargets.customers)))
          this.ProductsChartData.series[1] = Math.round(100 * (overallStats.app_customers / parseFloat(yearTargets.app_users)))

          this.ProductsChartData.total_items_count = overallStats.customers

          this.ProductsChartData.colors[0] = this.ProductsChartData.series[0] > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor
          this.ProductsChartData.colors[1] = this.ProductsChartData.series[1] > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor

          if (!period) {
            this.ProductsChartData.items_count[1] = {
              count: `${(overallStats.app_customers)} / ${(yearTargets.app_users)}`,
              title: 'App Users',
            }

            this.ProductsChartData.items_count[0] = {
              count: `${(overallStats.customers)} / ${(yearTargets.customers)}`,
              title: 'Shopify customers',
            }

            this.ProductsChartData.totalItem = {
              title: 'Customers',
              total: `${(overallStats.customers)}`,
            }
          }

          this.ScootersChartData.series[0] = Math.round(100 * (overallStats.rides / parseFloat(yearTargets.rides)))
          this.ScootersChartData.series[1] = Math.round(100 * (overallStats.odometer / parseFloat(yearTargets.odometer)))

          this.ScootersChartData.colors[0] = this.ScootersChartData.series[0] > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor
          this.ScootersChartData.colors[1] = this.ScootersChartData.series[1] > 100 ? this.colors.exceedPercentageColor : this.colors.percentageColor
          this.ScootersChartData.total_items_count = overallStats.scooters

          this.barsData = [
            {
              title: 'Customers',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.customers)} /  ${this.formattedValue(yearTargets.customers)}` : this.formattedValue(overallStats.customers),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.customers), this.cleanValue(yearTargets.customers)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.customers), this.cleanValue(yearTargets.customers), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'Shopify customers',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.buyers)} /  ${this.formattedValue(yearTargets.buyers)}` : this.formattedValue(overallStats.buyers),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.buyers), this.cleanValue(yearTargets.buyers)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.buyers), this.cleanValue(yearTargets.buyers), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'App Users',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.app_customers)} /  ${this.formattedValue(yearTargets.app_users)}` : this.formattedValue(overallStats.app_customers),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.app_customers), this.cleanValue(yearTargets.app_users)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.app_customers), this.cleanValue(yearTargets.app_users), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'Rides',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.rides)} /  ${this.formattedValue(yearTargets.rides)}` : this.formattedValue(overallStats.rides),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.rides), this.cleanValue(yearTargets.rides)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.rides), this.cleanValue(yearTargets.rides), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'Scooters',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.scooters)} /  ${this.formattedValue(yearTargets.scooters)}` : this.formattedValue(overallStats.scooters),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.scooters), this.cleanValue(yearTargets.scooters)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.scooters), this.cleanValue(yearTargets.scooters), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'Odometer',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.odometer)}Km /  ${this.formattedValue(yearTargets.odometer)}Km` : (parseInt(this.formattedValue(overallStats.odometer)) > 0 ? `${this.formattedValue(overallStats.odometer)}Km` : 0),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.odometer), this.cleanValue(yearTargets.odometer)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.odometer), this.cleanValue(yearTargets.odometer), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
            {
              title: 'Rides mileage',
              value: (period_data.isYearSelector || period_data.params == null ) ? `${this.formattedValue(overallStats.rides_mileage)}Km /  ${this.formattedValue(yearTargets.rides_mileage)}Km`: (parseInt(this.formattedValue(overallStats.rides_mileage)) > 0 ? `${this.formattedValue(overallStats.rides_mileage)}Km` : 0),
              percentage: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.rides_mileage), this.cleanValue(yearTargets.rides_mileage)) : '100%',
              percentageValue: (period_data.isYearSelector || period_data.params == null ) ? this.calculatePercentage(this.cleanValue(overallStats.rides_mileage), this.cleanValue(yearTargets.rides_mileage), true) : 0,
              showPercentage: false,
              hover: (period_data.isYearSelector || period_data.params == null )
            },
          ]
          if (!period) {
            this.ScootersChartData.items_count[0] = {
              count: `${this.formattedValue(overallStats.rides)} /  ${this.formattedValue(yearTargets.rides)}`,
              title: 'Ride',
            }
            this.ScootersChartData.items_count[1] = {
              count: `${this.formattedValue(overallStats.odometer)} / ${this.formattedValue(yearTargets.odometer)}KM`,
              title: 'Odometer',
            }
            this.ScootersChartData.totalItem = {
              title: 'Scooters',
              total: `${this.formattedValue(overallStats.scooters)} / ${this.formattedValue(yearTargets.scooters)}KM`,
            }
          }


          this.overallStatsLoading = false
          this.dataLoaded = true
          if (type !== 'both') {
            this[`${type}Loading`] = false
          }
          this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
        })
      })
    },
    showAnalyticsModalFn() {
      this.showAnalyticsModal = !this.showAnalyticsModal
    },
    load_sales() {
      // load sales stats.
      this.$http.get(this.$resources.admins.sales).then(response => {
        this.$http.get(this.$resources.targets.stores.list).then(targetResponse => {
          const currentYearTargets = targetResponse.data.data
          Object.keys(currentYearTargets).forEach(store => {
            this.storesFormData[store].data = currentYearTargets[store]
          })

          const currentMonth = moment().month() + 1

          const currentYearTargetsGrouped = {}
          const monthDays = moment().daysInMonth()

          Object.keys(currentYearTargets).forEach(element => {
            const sumOfYearTargets = Object.values(currentYearTargets[element]).reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0,
            )

            currentYearTargetsGrouped[element] = {
              yasterday: currentYearTargets[element],
              mtd: currentYearTargets[element][currentMonth],
              ytd: sumOfYearTargets,
              dailyTarget: (sumOfYearTargets || 0) / monthDays,
            }
          })

          const { usa, ca, subs } = response.data.data

          this.setChartData('Canada', {
            currentMonthTarget: currentYearTargetsGrouped?.ca?.mtd || 0, yearTarget: currentYearTargetsGrouped?.ca?.ytd || 0, dailyTarget: currentYearTargetsGrouped?.ca?.dailyTarget, monthDays, sales: ca,
          })

          this.setChartData('Usa', {
            currentMonthTarget: currentYearTargetsGrouped?.usa?.mtd || 0, yearTarget: currentYearTargetsGrouped?.usa?.ytd || 0, dailyTarget: currentYearTargetsGrouped?.usa?.dailyTarget, monthDays, sales: usa,
          })

          this.setChartData('Subscription', {
            currentMonthTarget: currentYearTargetsGrouped?.subs?.mtd || 0, yearTarget: currentYearTargetsGrouped?.subs?.ytd || 0, dailyTarget: currentYearTargetsGrouped?.subs?.dailyTarget, monthDays, sales: subs,
          })

          this.chartsLoading = false
        })
      })
    },


    load_home_items(showLoader = true,period = null) {

      if(showLoader) {
        this.homeItemsLoading = true
      }

      const  period_data = this.getPeramsData(period)

      this.$http.post(this.$resources.customers.calculations.stats, period_data.params).then(response => {
        const {
          data: {
            status, data: {
              usa, ca, subs, android, ios,
            },
          },
        } = response

        if (status === 200) {
          this.customersItems.usa.amount = usa || 0
          this.customersItems.ca.amount = ca || 0
          this.customersItems.subs.amount = subs || 0
          this.customersItems.android.amount = android || 0
          this.customersItems.ios.amount = ios || 0
        }
      })

      this.$http.post(this.$resources.rides.calculations.stats, period_data.params).then(response => {
          const { data: { status, data } } = response
          if (status === 200) {
            this.ridesItems.android.amount = data.android || 0
            this.ridesItems.avg_trip.amount = data.avg_trip || 0
            this.ridesItems.ios.amount = data.ios || 0
            this.ridesItems.mileage.amount = data.mileage || 0
            this.ridesItems.trips_per_scooter.amount = data.trips_per_scooter || 0
            this.homeItemsLoading = false
            this.homeCustomersItemCount++
          }
        })

    },

  },
}
</script>

<style lang="scss">
  @import '@/views/home/scss/style.scss';
</style>
